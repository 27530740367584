var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('wizard-left', {
    ref: "physicalPersonCreateWizard",
    attrs: {
      "clickable-steps": false,
      "scroll": _vm.scroll
    },
    scopedSlots: _vm._u([{
      key: "wizard-nav-left",
      fn: function fn() {
        return [_c('wizard-nav-step-left', {
          attrs: {
            "title": _vm.$t('General Information'),
            "desc": _vm.$t('Add main personal information'),
            "icon": "General/User.svg"
          }
        }), _c('wizard-nav-step-left', {
          attrs: {
            "title": _vm.$t('Contacts'),
            "desc": _vm.$t('Add personal contacts'),
            "icon": "Home/Library.svg"
          }
        }), _c('wizard-nav-step-left', {
          attrs: {
            "title": _vm.$t('Address'),
            "desc": _vm.$t('Add address of the person'),
            "icon": "Map/Marker1.svg"
          }
        }), _c('wizard-nav-step-left', {
          attrs: {
            "title": _vm.$t('Completed!'),
            "desc": _vm.$t('Review and Submit'),
            "icon": "General/Like.svg"
          }
        })];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn(_ref) {
        var currentStep = _ref.currentStep,
          goPrev = _ref.goPrev;
        return [_c('validation-observer', {
          ref: "mainValidationObserver",
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var _vm$form$place_birth, _vm$form$physicalpers, _vm$form$physicalpers2, _vm$form$physicalpers3, _vm$form$physicalpers4, _vm$form$physicalpers5;
              var handleSubmit = _ref2.handleSubmit;
              return [_c('wizard-step-left', {
                staticClass: "custom-wizard-step"
              }, [currentStep === 1 ? _c('validation-observer', {
                ref: "validator-1"
              }, [_c('div', {
                staticClass: "form row"
              }, [_c('div', {
                staticClass: "col-12"
              }, [_c('h4', {
                staticClass: "mb-10 font-weight-bold text-dark"
              }, [_vm._v(_vm._s(_vm.$t("Enter Account Details")))])]), _c('div', {
                staticClass: "col-12"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Avatar'),
                  "vid": "avatar_tus"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref3) {
                    var errors = _ref3.errors;
                    return [_c('avatar-input', {
                      attrs: {
                        "error-messages": errors
                      },
                      on: {
                        "change": function change(tusId) {
                          _vm.form.avatar_tus = tusId;
                        }
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-12 mb-4"
              }, [_vm.canAddAsEmployee ? _c('div', {
                staticClass: "align-items-center mb-4",
                class: {
                  'd-none': _vm.isEmployee,
                  'd-flex': !_vm.isEmployee
                }
              }, [_c('label', {
                class: {
                  'checkbox checkbox-lg': 'true',
                  'checkbox-disabled': _vm.form.secondment_client || _vm.form.secondment_contractor || _vm.form.secondment_partner
                }
              }, [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.is_employee,
                  expression: "form.is_employee"
                }],
                attrs: {
                  "type": "checkbox",
                  "disabled": _vm.form.secondment_client || _vm.form.secondment_contractor || _vm.form.secondment_partner
                },
                domProps: {
                  "checked": Array.isArray(_vm.form.is_employee) ? _vm._i(_vm.form.is_employee, null) > -1 : _vm.form.is_employee
                },
                on: {
                  "change": function change($event) {
                    var $$a = _vm.form.is_employee,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false;
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v);
                      if ($$el.checked) {
                        $$i < 0 && _vm.$set(_vm.form, "is_employee", $$a.concat([$$v]));
                      } else {
                        $$i > -1 && _vm.$set(_vm.form, "is_employee", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                      }
                    } else {
                      _vm.$set(_vm.form, "is_employee", $$c);
                    }
                  }
                }
              }), _c('span', {
                staticClass: "mr-3"
              }), _vm._v(" " + _vm._s(_vm.$t("Add as employee")) + " ")])]) : _vm._e(), !_vm.isEmployee ? _c('div', {
                staticClass: "mb-3"
              }, [_vm._v(_vm._s(_vm.$t("Or")))]) : _vm._e(), !_vm.isEmployee ? _c('div', {
                staticClass: "checkbox-inline"
              }, [_c('label', {
                class: {
                  'checkbox checkbox-lg': 'true',
                  'checkbox-disabled': _vm.form.is_employee
                }
              }, [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.secondment_client,
                  expression: "form.secondment_client"
                }],
                attrs: {
                  "type": "checkbox",
                  "disabled": _vm.form.is_employee
                },
                domProps: {
                  "checked": Array.isArray(_vm.form.secondment_client) ? _vm._i(_vm.form.secondment_client, null) > -1 : _vm.form.secondment_client
                },
                on: {
                  "change": function change($event) {
                    var $$a = _vm.form.secondment_client,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false;
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v);
                      if ($$el.checked) {
                        $$i < 0 && _vm.$set(_vm.form, "secondment_client", $$a.concat([$$v]));
                      } else {
                        $$i > -1 && _vm.$set(_vm.form, "secondment_client", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                      }
                    } else {
                      _vm.$set(_vm.form, "secondment_client", $$c);
                    }
                  }
                }
              }), _c('span'), _vm._v(" " + _vm._s(_vm.$t("Add as client")) + " ")]), _c('label', {
                class: {
                  'checkbox checkbox-lg': 'true',
                  'checkbox-disabled': _vm.form.is_employee
                }
              }, [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.secondment_contractor,
                  expression: "form.secondment_contractor"
                }],
                attrs: {
                  "type": "checkbox",
                  "disabled": _vm.form.is_employee
                },
                domProps: {
                  "checked": Array.isArray(_vm.form.secondment_contractor) ? _vm._i(_vm.form.secondment_contractor, null) > -1 : _vm.form.secondment_contractor
                },
                on: {
                  "change": function change($event) {
                    var $$a = _vm.form.secondment_contractor,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false;
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v);
                      if ($$el.checked) {
                        $$i < 0 && _vm.$set(_vm.form, "secondment_contractor", $$a.concat([$$v]));
                      } else {
                        $$i > -1 && _vm.$set(_vm.form, "secondment_contractor", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                      }
                    } else {
                      _vm.$set(_vm.form, "secondment_contractor", $$c);
                    }
                  }
                }
              }), _c('span'), _vm._v(" " + _vm._s(_vm.$t("Add as contractor")) + " ")]), _c('label', {
                class: {
                  'checkbox checkbox-lg': 'true',
                  'checkbox-disabled': _vm.form.is_employee
                }
              }, [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.secondment_partner,
                  expression: "form.secondment_partner"
                }],
                attrs: {
                  "type": "checkbox",
                  "disabled": _vm.form.is_employee
                },
                domProps: {
                  "checked": Array.isArray(_vm.form.secondment_partner) ? _vm._i(_vm.form.secondment_partner, null) > -1 : _vm.form.secondment_partner
                },
                on: {
                  "change": function change($event) {
                    var $$a = _vm.form.secondment_partner,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false;
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v);
                      if ($$el.checked) {
                        $$i < 0 && _vm.$set(_vm.form, "secondment_partner", $$a.concat([$$v]));
                      } else {
                        $$i > -1 && _vm.$set(_vm.form, "secondment_partner", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                      }
                    } else {
                      _vm.$set(_vm.form, "secondment_partner", $$c);
                    }
                  }
                }
              }), _c('span'), _vm._v(" " + _vm._s(_vm.$t("Add as supplier")) + " ")])]) : _vm._e()]), _c('div', {
                staticClass: "col-6"
              }, [_c('validation-provider', {
                attrs: {
                  "vid": "name",
                  "name": _vm.$t('Name'),
                  "rules": "required|max:30"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref4) {
                    var errors = _ref4.errors;
                    return [_c('euro-input', {
                      attrs: {
                        "label": "".concat(_vm.$t('First name'), "*"),
                        "error-messages": errors
                      },
                      model: {
                        value: _vm.form.name,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "name", $$v);
                        },
                        expression: "form.name"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-6"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Last name'),
                  "vid": "surname",
                  "rules": "required|max:30"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref5) {
                    var errors = _ref5.errors;
                    return [_c('euro-input', {
                      attrs: {
                        "label": "".concat(_vm.$t('Last name'), "*"),
                        "error-messages": errors
                      },
                      model: {
                        value: _vm.form.surname,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "surname", $$v);
                        },
                        expression: "form.surname"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-6"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Gender'),
                  "vid": "gender",
                  "rules": "required"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref6) {
                    var errors = _ref6.errors;
                    return [_c('euro-radio', {
                      attrs: {
                        "options": _vm.GENDERS,
                        "label": "".concat(_vm.$t('Gender'), "*"),
                        "error-messages": errors
                      },
                      model: {
                        value: _vm.form.gender,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "gender", $$v);
                        },
                        expression: "form.gender"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-6"
              }, [_c('validation-provider', {
                ref: "dateBirthProvider",
                attrs: {
                  "name": _vm.$t('Date of birth'),
                  "vid": "date_birth"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref7) {
                    var errors = _ref7.errors;
                    return [_c('date-picker', {
                      attrs: {
                        "overrides": {
                          maxDate: _vm.moment(),
                          useCurrent: false
                        },
                        "error-messages": errors,
                        "placeholder": "YYYY-MM-DD",
                        "label": "".concat(_vm.$t('Date of birth'))
                      },
                      model: {
                        value: _vm.form.date_birth,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "date_birth", $$v);
                        },
                        expression: "form.date_birth"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-12"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Place of birth'),
                  "vid": "place_birth"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref8) {
                    var errors = _ref8.errors;
                    return [_vm.googleMapCall == true ? _c('div', [_c('euro-place-input', {
                      attrs: {
                        "label": "".concat(_vm.$t('Place of birth')),
                        "error-messages": errors
                      },
                      model: {
                        value: _vm.form.place_birth,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "place_birth", $$v);
                        },
                        expression: "form.place_birth"
                      }
                    })], 1) : _vm._e()];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-12"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Citizenships'),
                  "vid": "citizenships",
                  "rules": "required"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref9) {
                    var errors = _ref9.errors;
                    return [_c('euro-select', {
                      attrs: {
                        "label": "".concat(_vm.$t('Citizenships'), "*"),
                        "placeholder": _vm.$t('Select citizenships'),
                        "options": _vm.nationsSelectable,
                        "multiple": "",
                        "searchable": "",
                        "error-messages": errors
                      },
                      model: {
                        value: _vm.citizenshipsComp,
                        callback: function callback($$v) {
                          _vm.citizenshipsComp = $$v;
                        },
                        expression: "citizenshipsComp"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-12"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Marital Status'),
                  "vid": "marital_status"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref10) {
                    var errors = _ref10.errors;
                    return [_c('euro-select', {
                      attrs: {
                        "label": "".concat(_vm.$t('Marital Status')),
                        "placeholder": _vm.$t('Select a marital status'),
                        "options": _vm.MARITAL_STATUS,
                        "searchable": "",
                        "error-messages": errors
                      },
                      model: {
                        value: _vm.form.marital_status,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "marital_status", $$v);
                        },
                        expression: "form.marital_status"
                      }
                    })];
                  }
                }], null, true)
              })], 1)])]) : _vm._e()], 1), _c('wizard-step-left', {
                staticClass: "custom-wizard-step"
              }, [currentStep === 2 ? _c('validation-observer', {
                ref: "validator-2"
              }, [_c('div', {
                staticClass: "form row"
              }, [_c('div', {
                staticClass: "col-12"
              }, [_c('h4', {
                staticClass: "mb-10 font-weight-bold text-dark"
              }, [_vm._v(" " + _vm._s(_vm.$t("Set up contact information")) + " ")])]), _c('div', {
                staticClass: "col-12 mb-5"
              }, [_c('validation-provider', {
                attrs: {
                  "vid": "telcontact_set"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref11) {
                    var errors = _ref11.errors;
                    return [_c('multi-telephones-input', {
                      attrs: {
                        "label": _vm.$t('Enter phone numbers'),
                        "error-messages": errors,
                        "options": _vm.TELEPHONE_KINDS,
                        "default-country": _vm.form.citizenship_set[0].country
                      },
                      model: {
                        value: _vm.form.telcontact_set,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "telcontact_set", $$v);
                        },
                        expression: "form.telcontact_set"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', [_c('div', {
                staticClass: "col-12 mb-5"
              }, [_c('validation-provider', {
                attrs: {
                  "vid": "extraemail_set"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref12) {
                    var errors = _ref12.errors;
                    return [_c('multi-emails-input', {
                      attrs: {
                        "label": _vm.$t('Enter email address'),
                        "error-messages": errors,
                        "options": _vm.EMAIL_KINDS
                      },
                      model: {
                        value: _vm.form.extraemail_set,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "extraemail_set", $$v);
                        },
                        expression: "form.extraemail_set"
                      }
                    })];
                  }
                }], null, true)
              })], 1)]), _c('div', {
                staticClass: "col-12 mb-5"
              }, [_c('validation-provider', {
                attrs: {
                  "vid": "social_contacts"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref13) {
                    var errors = _ref13.errors;
                    return [_c('multi-social-input', {
                      key: _vm.multiSocialInputKey,
                      attrs: {
                        "label": _vm.$t('Enter social media profiles'),
                        "options": _vm.SOCIAL_KINDS,
                        "error-messages": errors
                      },
                      model: {
                        value: _vm.socialContactsCpy,
                        callback: function callback($$v) {
                          _vm.socialContactsCpy = $$v;
                        },
                        expression: "socialContactsCpy"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-12"
              }, [_c('validation-provider', {
                attrs: {
                  "name": "Website",
                  "vid": "Website",
                  "rules": "url"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref14) {
                    var errors = _ref14.errors;
                    return [_c('euro-input', {
                      attrs: {
                        "label": _vm.$t('Enter website'),
                        "error-messages": errors,
                        "placeholder": _vm.$t('https://www.example.com  or  www.example.com')
                      },
                      model: {
                        value: _vm.website,
                        callback: function callback($$v) {
                          _vm.website = $$v;
                        },
                        expression: "website"
                      }
                    })];
                  }
                }], null, true)
              })], 1)])]) : _vm._e()], 1), _c('wizard-step-left', {
                staticClass: "custom-wizard-step"
              }, [currentStep === 3 ? _c('validation-observer', {
                ref: "validator-3"
              }, [_c('h3', {
                staticClass: "mb-10 font-weight-bold text-dark"
              }, [_vm._v(" " + _vm._s(_vm.$t("Setup Your Address")) + " ")]), _c('validation-provider', {
                attrs: {
                  "vid": "address_description"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref15) {
                    var errors = _ref15.errors;
                    return [_c('euro-input', {
                      attrs: {
                        "label": _vm.$t('Description'),
                        "errors": errors
                      },
                      model: {
                        value: _vm.form.physicalpersonaddress_set.description,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form.physicalpersonaddress_set, "description", $$v);
                        },
                        expression: "form.physicalpersonaddress_set.description"
                      }
                    })];
                  }
                }], null, true)
              }), _c('div', {
                staticClass: "row"
              }, [_c('div', {
                staticClass: "col-8"
              }, [_c('validation-provider', {
                attrs: {
                  "vid": "address_kind"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref16) {
                    var errors = _ref16.errors;
                    return [_c('euro-select', {
                      attrs: {
                        "label": _vm.$t('Kind'),
                        "options": _vm.selectableKinds,
                        "error-messagess": errors
                      },
                      model: {
                        value: _vm.form.physicalpersonaddress_set.kind,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form.physicalpersonaddress_set, "kind", $$v);
                        },
                        expression: "form.physicalpersonaddress_set.kind"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-4"
              }, [_c('div', {
                staticClass: "form-group mb-0 mt-12"
              }, [_c('div', {
                staticClass: "d-flex align-items-center justify-content-end"
              }, [_c('label', {
                staticClass: "col-form-label"
              }, [_vm._v(_vm._s(_vm.$t('Main address')))]), _c('div', {
                staticClass: "ml-3"
              }, [_c('span', {
                staticClass: "switch switch-sm switch-icon"
              }, [_c('label', [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.physicalpersonaddress_set.main,
                  expression: "form.physicalpersonaddress_set.main"
                }],
                attrs: {
                  "type": "checkbox"
                },
                domProps: {
                  "checked": Array.isArray(_vm.form.physicalpersonaddress_set.main) ? _vm._i(_vm.form.physicalpersonaddress_set.main, null) > -1 : _vm.form.physicalpersonaddress_set.main
                },
                on: {
                  "change": function change($event) {
                    var $$a = _vm.form.physicalpersonaddress_set.main,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false;
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v);
                      if ($$el.checked) {
                        $$i < 0 && _vm.$set(_vm.form.physicalpersonaddress_set, "main", $$a.concat([$$v]));
                      } else {
                        $$i > -1 && _vm.$set(_vm.form.physicalpersonaddress_set, "main", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                      }
                    } else {
                      _vm.$set(_vm.form.physicalpersonaddress_set, "main", $$c);
                    }
                  }
                }
              }), _c('span')])])])])])])]), _c('validation-provider', {
                attrs: {
                  "vid": "address_address"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref17) {
                    var errors = _ref17.errors;
                    return [_c('AddressInput', {
                      attrs: {
                        "label": _vm.$t('Address'),
                        "manual-address": _vm.form.physicalpersonaddress_set.manual_address,
                        "error-messages": errors,
                        "main": _vm.form.physicalpersonaddress_set.main,
                        "active-step": currentStep === 3,
                        "address-center": _vm.addressCenter
                      },
                      on: {
                        "change-manual-address": _vm.changeManualAddress,
                        "update-composed-address": _vm.updateComposedAddress
                      },
                      model: {
                        value: _vm.form.physicalpersonaddress_set.address,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form.physicalpersonaddress_set, "address", $$v);
                        },
                        expression: "form.physicalpersonaddress_set.address"
                      }
                    })];
                  }
                }], null, true)
              })], 1) : _vm._e()], 1), currentStep === 4 ? _c('wizard-step-left', {
                staticClass: "custom-wizard-step"
              }, [_c('div', [_c('h6', {
                staticClass: "font-weight-bolder mb-3"
              }, [_vm._v(_vm._s(_vm.$t("General Information")))]), _c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, [_c('div', [_c('span', {
                staticClass: "font-weight-bolder mr-2"
              }, [_vm._v(_vm._s(_vm.$t("Name")) + ":")]), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm.form.name))])]), _c('div', [_c('span', {
                staticClass: "font-weight-bolder mr-2"
              }, [_vm._v(_vm._s(_vm.$t("Last name")) + ":")]), _c('span', [_vm._v(_vm._s(_vm.form.surname))])]), _c('div', [_c('span', {
                staticClass: "font-weight-bolder mr-2"
              }, [_vm._v(_vm._s(_vm.$t("Gender")) + ":")]), _c('span', [_vm._v(_vm._s(_vm.GENDERS.find(function (el) {
                return el.value == _vm.form.gender;
              }).text))])]), _vm.form.marital_status ? _c('div', [_c('span', {
                staticClass: "font-weight-bolder mr-2"
              }, [_vm._v(_vm._s(_vm.$t("Marital Status")) + ":")]), _c('span', [_vm._v(_vm._s(_vm.MARITAL_STATUS.find(function (el) {
                return el.value == _vm.form.marital_status;
              }).text))])]) : _vm._e(), _vm.form.place_birth ? _c('div', [_c('span', {
                staticClass: "font-weight-bolder mr-2"
              }, [_vm._v(_vm._s(_vm.$t("Place of birth")) + ":")]), _c('span', [_vm._v(_vm._s((_vm$form$place_birth = _vm.form.place_birth) === null || _vm$form$place_birth === void 0 ? void 0 : _vm$form$place_birth.formatted_address))])]) : _vm._e(), _vm.form.date_birth ? _c('div', [_c('span', {
                staticClass: "font-weight-bolder mr-2"
              }, [_vm._v(_vm._s(_vm.$t("Date of birth")) + ":")]), _c('span', [_vm._v(" " + _vm._s(_vm.DateService.format(_vm.form.date_birth, _vm.dateFormat)) + " ")])]) : _vm._e(), _vm.website ? _c('div', [_c('span', {
                staticClass: "font-weight-bolder mr-2"
              }, [_vm._v(_vm._s(_vm.$t("Enter website")) + ":")]), _c('span', {
                style: {
                  wordBreak: 'break-word'
                }
              }, [_vm._v(_vm._s(_vm.website))])]) : _vm._e()]), _vm.form.telcontact_set.filter(function (el) {
                return el.number != '';
              }).length ? _c('div', [_c('div', {
                staticClass: "separator separator-dashed my-5"
              }), _c('h6', {
                staticClass: "font-weight-bolder mb-3"
              }, [_vm._v(_vm._s(_vm.$t("Telephone Contacts")))]), _c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, _vm._l(_vm.form.telcontact_set, function (telContact, idx) {
                return _c('div', {
                  key: idx,
                  attrs: {
                    "striped": !(idx % 2)
                  }
                }, [_c('span', {
                  staticClass: "font-weight-bolder mr-2"
                }, [_vm._v(_vm._s(_vm.TELEPHONE_KINDS.find(function (el) {
                  return el.value == telContact.kind;
                }).text) + ":")]), _c('span', [_vm._v(" " + _vm._s(telContact.number) + " " + _vm._s(telContact.main ? "- Main" : "") + " ")])]);
              }), 0)]) : _vm._e(), _vm.form.extraemail_set.filter(function (el) {
                return el.email != '';
              }).length ? _c('div', [_c('div', {
                staticClass: "separator separator-dashed my-5"
              }), _c('h6', {
                staticClass: "font-weight-bolder mb-3"
              }, [_vm._v(_vm._s(_vm.$t("Email Contacts")))]), _c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, _vm._l(_vm.form.extraemail_set, function (mail, idx) {
                return _c('div', {
                  key: idx,
                  attrs: {
                    "striped": !(idx % 2)
                  }
                }, [_c('span', {
                  staticClass: "font-weight-bolder mr-2"
                }, [_vm._v(_vm._s(_vm.EMAIL_KINDS.find(function (el) {
                  return el.value == mail.kind;
                }).text) + ":")]), _c('span', [_vm._v(" " + _vm._s(mail.email) + " " + _vm._s(mail.main ? "- Main" : "") + " ")])]);
              }), 0)]) : _vm._e(), _vm.socialContactsCpy.length ? _c('div', [_c('div', {
                staticClass: "separator separator-dashed my-5"
              }), _c('h6', {
                staticClass: "font-weight-bolder mb-3"
              }, [_vm._v(_vm._s(_vm.$t("Social Contacts")))]), _c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, _vm._l(_vm.socialContactsCpy, function (social, idx) {
                return _c('div', {
                  key: idx,
                  attrs: {
                    "striped": !(idx % 2)
                  }
                }, [_c('span', {
                  staticClass: "font-weight-bolder mr-2"
                }, [_vm._v(_vm._s(_vm.SOCIAL_KINDS.find(function (el) {
                  return el.value == social.kind;
                }).text) + ":")]), _c('span', [_vm._v(_vm._s(social.contact))])]);
              }), 0)]) : _vm._e(), (_vm$form$physicalpers = _vm.form.physicalpersonaddress_set) !== null && _vm$form$physicalpers !== void 0 && (_vm$form$physicalpers2 = _vm$form$physicalpers.address) !== null && _vm$form$physicalpers2 !== void 0 && _vm$form$physicalpers2.formatted_address ? _c('div', [_c('div', {
                staticClass: "separator separator-dashed my-5"
              }), _c('h6', {
                staticClass: "font-weight-bolder mb-3"
              }, [_vm._v("Address")]), _vm.form.physicalpersonaddress_set.manual_address ? _c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, [_vm._v(" " + _vm._s(_vm.form.physicalpersonaddress_set.composed_address.formatted) + " "), _vm.form.physicalpersonaddress_set.description ? _c('div', [_c('span', [_vm._v("Description: " + _vm._s(_vm.form.physicalpersonaddress_set.description))])]) : _vm._e()]) : _c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, [_vm._v(" " + _vm._s((_vm$form$physicalpers3 = (_vm$form$physicalpers4 = _vm.form.physicalpersonaddress_set) === null || _vm$form$physicalpers4 === void 0 ? void 0 : (_vm$form$physicalpers5 = _vm$form$physicalpers4.address) === null || _vm$form$physicalpers5 === void 0 ? void 0 : _vm$form$physicalpers5.formatted_address) !== null && _vm$form$physicalpers3 !== void 0 ? _vm$form$physicalpers3 : "No address found") + " "), _vm.form.physicalpersonaddress_set.description ? _c('div', [_c('span', [_vm._v("Description: " + _vm._s(_vm.form.physicalpersonaddress_set.description))])]) : _vm._e()])]) : _vm._e()])]) : _vm._e(), _c('wizard-actions-left', [_c('div', {
                staticClass: "mr-2"
              }, [_c('wizard-prev-btn-left', {
                on: {
                  "click": goPrev
                }
              })], 1), _c('div', [_c('wizard-submit-btn-left', {
                attrs: {
                  "label": _vm.$t('Save')
                },
                on: {
                  "click": function click($event) {
                    return handleSubmit(_vm.submit);
                  }
                }
              }), _vm.form.is_employee && !_vm.buttonDisabled ? _c('wizard-submit-btn-left', {
                attrs: {
                  "label": _vm.$t('Save and add Employee Contract')
                },
                on: {
                  "click": function click() {
                    _vm.addEmployeeContract = true;
                    handleSubmit(_vm.submit);
                  }
                }
              }) : _vm._e(), _c('wizard-next-btn-left', {
                on: {
                  "click": _vm.handleNext
                }
              })], 1)])];
            }
          }], null, true)
        })];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }