<template>
  <wizard-left ref="physicalPersonCreateWizard" :clickable-steps="false" :scroll="scroll">
    <template #wizard-nav-left>
      <wizard-nav-step-left :title="$t('General Information')" :desc="$t('Add main personal information')"
        icon="General/User.svg"></wizard-nav-step-left>
      <wizard-nav-step-left :title="$t('Contacts')" :desc="$t('Add personal contacts')"
        icon="Home/Library.svg"></wizard-nav-step-left>
      <wizard-nav-step-left :title="$t('Address')" :desc="$t('Add address of the person')"
        icon="Map/Marker1.svg"></wizard-nav-step-left>
      <wizard-nav-step-left :title="$t('Completed!')" :desc="$t('Review and Submit')" icon="General/Like.svg" />
    </template>

    <template #default="{ currentStep, goPrev }">
      <validation-observer ref="mainValidationObserver" v-slot="{ handleSubmit }">
        <wizard-step-left class="custom-wizard-step">
          <validation-observer v-if="currentStep === 1" ref="validator-1">
            <div class="form row">
              <div class="col-12">
                <h4 class="mb-10 font-weight-bold text-dark">{{ $t("Enter Account Details") }}</h4>
              </div>
              <div class="col-12">
                <validation-provider v-slot="{ errors }" :name="$t('Avatar')" vid="avatar_tus">
                  <avatar-input :error-messages="errors" @change="tusId => { form.avatar_tus = tusId; }"></avatar-input>
                </validation-provider>
              </div>
              <div class="col-12 mb-4">
                <div v-if="canAddAsEmployee" class="align-items-center mb-4"
                  :class="{ 'd-none': isEmployee, 'd-flex': !isEmployee }">
                  <label
                    :class="{ 'checkbox checkbox-lg': 'true', 'checkbox-disabled': form.secondment_client || form.secondment_contractor || form.secondment_partner }">
                    <input v-model="form.is_employee" type="checkbox"
                      :disabled="form.secondment_client || form.secondment_contractor || form.secondment_partner" />
                    <span class="mr-3"></span>
                    {{ $t("Add as employee") }}
                  </label>
                </div>
                <div v-if="!isEmployee" class="mb-3">{{ $t("Or") }}</div>
                <div v-if="!isEmployee" class="checkbox-inline">
                  <label :class="{ 'checkbox checkbox-lg': 'true', 'checkbox-disabled': form.is_employee }">
                    <input v-model="form.secondment_client" type="checkbox" :disabled="form.is_employee">
                    <span></span>
                    {{ $t("Add as client") }}
                  </label>
                  <label :class="{ 'checkbox checkbox-lg': 'true', 'checkbox-disabled': form.is_employee }">
                    <input v-model="form.secondment_contractor" type="checkbox" :disabled="form.is_employee">
                    <span></span>
                    {{ $t("Add as contractor") }}
                  </label>
                  <label :class="{ 'checkbox checkbox-lg': 'true', 'checkbox-disabled': form.is_employee }">
                    <input v-model="form.secondment_partner" type="checkbox" :disabled="form.is_employee">
                    <span></span>
                    {{ $t("Add as supplier") }}
                  </label>
                </div>
              </div>
              <div class="col-6">
                <validation-provider v-slot="{ errors }" vid="name" :name="$t('Name')" rules="required|max:30">
                  <euro-input v-model="form.name" :label="`${$t('First name')}*`" :error-messages="errors"></euro-input>
                </validation-provider>
              </div>
              <div class="col-6">
                <validation-provider v-slot="{ errors }" :name="$t('Last name')" vid="surname" rules="required|max:30">
                  <euro-input v-model="form.surname" :label="`${$t('Last name')}*`"
                    :error-messages="errors"></euro-input>
                </validation-provider>
              </div>
              <div class="col-6">
                <validation-provider v-slot="{ errors }" :name="$t('Gender')" vid="gender" rules="required">
                  <euro-radio v-model="form.gender" :options="GENDERS" :label="`${$t('Gender')}*`"
                    :error-messages="errors"></euro-radio>
                </validation-provider>
              </div>
              <div class="col-6">
                <validation-provider ref="dateBirthProvider" v-slot="{ errors }" :name="$t('Date of birth')"
                  vid="date_birth">
                  <date-picker v-model="form.date_birth" :overrides="{ maxDate: moment(), useCurrent: false }"
                    :error-messages="errors" placeholder="YYYY-MM-DD" :label="`${$t('Date of birth')}`">
                  </date-picker>
                </validation-provider>
              </div>
              <div class="col-12">
                <validation-provider v-slot="{ errors }" :name="$t('Place of birth')" vid="place_birth">
                  <div v-if="googleMapCall == true">
                    <euro-place-input v-model="form.place_birth" :label="`${$t('Place of birth')}`"
                      :error-messages="errors"></euro-place-input>
                  </div>
                </validation-provider>
              </div>
              <div class="col-12">
                <validation-provider v-slot="{ errors }" :name="$t('Citizenships')" vid="citizenships" rules="required">
                  <euro-select v-model="citizenshipsComp" :label="`${$t('Citizenships')}*`"
                    :placeholder="$t('Select citizenships')" :options="nationsSelectable" multiple searchable
                    :error-messages="errors"></euro-select>
                </validation-provider>
              </div>
              <div class="col-12">
                <validation-provider v-slot="{ errors }" :name="$t('Marital Status')" vid="marital_status">
                  <euro-select v-model="form.marital_status" :label="`${$t('Marital Status')}`"
                    :placeholder="$t('Select a marital status')" :options="MARITAL_STATUS" searchable
                    :error-messages="errors"></euro-select>
                </validation-provider>
              </div>
            </div>
          </validation-observer>
        </wizard-step-left>
        <wizard-step-left class="custom-wizard-step">
          <validation-observer v-if="currentStep === 2" ref="validator-2">
            <div class="form row">
              <div class="col-12">
                <h4 class="mb-10 font-weight-bold text-dark">
                  {{ $t("Set up contact information") }}
                </h4>
              </div>
              <div class="col-12 mb-5">
                <validation-provider v-slot="{ errors }" vid="telcontact_set">
                  <multi-telephones-input v-model="form.telcontact_set" :label="$t('Enter phone numbers')"
                    :error-messages="errors" :options="TELEPHONE_KINDS"
                    :default-country="form.citizenship_set[0].country"></multi-telephones-input>
                </validation-provider>
              </div>
              <div>
                <div class="col-12 mb-5">
                  <validation-provider v-slot="{ errors }" vid="extraemail_set">
                    <multi-emails-input v-model="form.extraemail_set" :label="$t('Enter email address')"
                      :error-messages="errors" :options="EMAIL_KINDS"></multi-emails-input>
                  </validation-provider>
                </div>
              </div>
              <div class="col-12 mb-5">
                <validation-provider v-slot="{ errors }" vid="social_contacts">
                  <multi-social-input :key="multiSocialInputKey" v-model="socialContactsCpy"
                    :label="$t('Enter social media profiles')" :options="SOCIAL_KINDS"
                    :error-messages="errors"></multi-social-input>
                </validation-provider>
              </div>
              <div class="col-12">
                <validation-provider v-slot="{ errors }" name="Website" vid="Website" rules="url">
                  <euro-input v-model="website" :label="$t('Enter website')" :error-messages="errors"
                    :placeholder="$t('https://www.example.com  or  www.example.com')"></euro-input>
                </validation-provider>
              </div>
            </div>
          </validation-observer>
        </wizard-step-left>
        <wizard-step-left class="custom-wizard-step">
          <validation-observer v-if="currentStep === 3" ref="validator-3">
            <h3 class="mb-10 font-weight-bold text-dark">
              {{ $t("Setup Your Address") }}
            </h3>

            <validation-provider v-slot="{ errors }" vid="address_description">
              <euro-input v-model="form.physicalpersonaddress_set.description" :label="$t('Description')"
                :errors="errors"></euro-input>
            </validation-provider>
            <div class="row">
              <div class="col-8">
                <validation-provider v-slot="{ errors }" vid="address_kind">
                  <euro-select v-model="form.physicalpersonaddress_set.kind" :label="$t('Kind')"
                    :options="selectableKinds" :error-messagess="errors"></euro-select>
                </validation-provider>
              </div>
              <div class="col-4">
                <div class="form-group mb-0 mt-12">
                  <div class="d-flex align-items-center justify-content-end">
                    <label class="col-form-label">{{ $t('Main address') }}</label>
                    <div class="ml-3">
                      <span class="switch switch-sm switch-icon">
                        <label>
                          <input v-model="form.physicalpersonaddress_set.main" type="checkbox" />
                          <span></span>
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <validation-provider v-slot="{ errors }" vid="address_address">
              <AddressInput v-model="form.physicalpersonaddress_set.address" :label="$t('Address')"
                :manual-address="form.physicalpersonaddress_set.manual_address" :error-messages="errors"
                :main="form.physicalpersonaddress_set.main" :active-step="currentStep === 3"
                :address-center="addressCenter" @change-manual-address="changeManualAddress"
                @update-composed-address="updateComposedAddress">
              </AddressInput>
            </validation-provider>
          </validation-observer>
        </wizard-step-left>
        <wizard-step-left v-if="currentStep === 4" class="custom-wizard-step">
          <div>
            <h6 class="font-weight-bolder mb-3">{{ $t("General Information") }}</h6>
            <div class="text-dark-50 line-height-lg">
              <div>
                <span class="font-weight-bolder mr-2">{{ $t("Name") }}:</span> <span>{{ form.name }}</span>
              </div>
              <div>
                <span class="font-weight-bolder mr-2">{{ $t("Last name") }}:</span>
                <span>{{ form.surname }}</span>
              </div>
              <div>
                <span class="font-weight-bolder mr-2">{{ $t("Gender") }}:</span>
                <span>{{ GENDERS.find(el => el.value == form.gender).text }}</span>
              </div>
              <div v-if="form.marital_status">
                <span class="font-weight-bolder mr-2">{{ $t("Marital Status") }}:</span>
                <span>{{ MARITAL_STATUS.find(el => el.value == form.marital_status).text }}</span>
              </div>
              <div v-if="form.place_birth">
                <span class="font-weight-bolder mr-2">{{ $t("Place of birth") }}:</span>
                <span>{{ form.place_birth?.formatted_address }}</span>
              </div>
              <div v-if="form.date_birth">
                <span class="font-weight-bolder mr-2">{{ $t("Date of birth") }}:</span>
                <span>
                  {{ DateService.format(form.date_birth, dateFormat) }}
                </span>
              </div>
              <div v-if="website">
                <span class="font-weight-bolder mr-2">{{ $t("Enter website") }}:</span>
                <span :style="{ wordBreak: 'break-word' }">{{ website }}</span>
              </div>
            </div>
            <div v-if="form.telcontact_set.filter(el => el.number != '').length">
              <div class="separator separator-dashed my-5"></div>
              <h6 class="font-weight-bolder mb-3">{{ $t("Telephone Contacts") }}</h6>
              <div class="text-dark-50 line-height-lg">
                <div v-for="(  telContact, idx  ) in   form.telcontact_set  " :key="idx" :striped="!(idx % 2)">
                  <span class="font-weight-bolder mr-2">{{ TELEPHONE_KINDS.find(el => el.value == telContact.kind).text
                    }}:</span>
                  <span>
                    {{ telContact.number }}
                    {{ telContact.main ? "- Main" : "" }}
                  </span>
                </div>
              </div>
            </div>
            <div v-if="form.extraemail_set.filter(el => el.email != '').length">
              <div class="separator separator-dashed my-5"></div>
              <h6 class="font-weight-bolder mb-3">{{ $t("Email Contacts") }}</h6>
              <div class="text-dark-50 line-height-lg">
                <div v-for="(  mail, idx  ) in   form.extraemail_set  " :key="idx" :striped="!(idx % 2)">
                  <span class="font-weight-bolder mr-2">{{ EMAIL_KINDS.find(el => el.value == mail.kind).text }}:</span>
                  <span>
                    {{ mail.email }}
                    {{ mail.main ? "- Main" : "" }}
                  </span>
                </div>
              </div>
            </div>
            <div v-if="socialContactsCpy.length">
              <div class="separator separator-dashed my-5"></div>
              <h6 class="font-weight-bolder mb-3">{{ $t("Social Contacts") }}</h6>
              <div class="text-dark-50 line-height-lg">
                <div v-for="(  social, idx  ) in   socialContactsCpy  " :key="idx" :striped="!(idx % 2)">
                  <span class="font-weight-bolder mr-2">{{ SOCIAL_KINDS.find(el => el.value == social.kind).text
                    }}:</span>
                  <span>{{ social.contact }}</span>
                </div>
              </div>
            </div>
            <div v-if="form.physicalpersonaddress_set?.address?.formatted_address">
              <div class="separator separator-dashed my-5"></div>
              <h6 class="font-weight-bolder mb-3">Address</h6>
              <div v-if="form.physicalpersonaddress_set.manual_address" class="text-dark-50 line-height-lg">
                {{ form.physicalpersonaddress_set.composed_address.formatted }}
                <div v-if="form.physicalpersonaddress_set.description">
                  <span>Description: {{ form.physicalpersonaddress_set.description }}</span>
                </div>
              </div>
              <div v-else class="text-dark-50 line-height-lg">
                {{ form.physicalpersonaddress_set?.address?.formatted_address ?? "No address found" }}
                <div v-if="form.physicalpersonaddress_set.description">
                  <span>Description: {{ form.physicalpersonaddress_set.description }}</span>
                </div>
              </div>
            </div>
          </div>
        </wizard-step-left>
        <wizard-actions-left>
          <div class="mr-2">
            <wizard-prev-btn-left @click="goPrev"></wizard-prev-btn-left>
          </div>
          <div>
            <wizard-submit-btn-left :label="$t('Save')" @click="handleSubmit(submit)">
            </wizard-submit-btn-left>
            <wizard-submit-btn-left v-if="form.is_employee && !buttonDisabled"
              :label="$t('Save and add Employee Contract')"
              @click="() => { addEmployeeContract = true; handleSubmit(submit) }">
            </wizard-submit-btn-left>
            <wizard-next-btn-left @click="handleNext"></wizard-next-btn-left>
          </div>
        </wizard-actions-left>
      </validation-observer>
    </template>
  </wizard-left>
</template>

<script>
import PhysicalPersonAddressService from "@/core/services/physical-person/physical-person-address.service";
import PhysicalPersonDetailService from "@/core/services/physical-person/physical-person-detail.service";
import PhysicalPersonTelephoneService from "@/core/services/physical-person/physical-person-telephone.service";
import PhysicalPersonEmailService from "@/core/services/physical-person/physical-person-email.service";
import PhysicalPersonSocialService from "@/core/services/physical-person/physical-person-social.service";
import datePicker from "@/view/components/datePicker/datePicker.vue";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import { getValidationErrors } from "@/core/helpers";
import { mapGetters, mapState } from "vuex";
import DateService from "@/core/services/date.service";

export default {
  components: {
    datePicker,
  },

  props: {
    errorMessages: {
      type: Object,
      default: null,
    },
    canAddAsEmployee: {
      type: Boolean,
      default: false,
    },
    isEmployee: {
      type: Boolean,
      default: false,
    },
    nested: {
      type: Boolean,
      default: false,
    },
    sendInvite: {
      type: Boolean,
      default: false,
    },
    scroll: {
      type: Boolean,
      default: true,
    }
  },


  data() {
    return {
      DateService,
      website: "",
      socialContactsCpy: [],
      multiSocialInputKey: 0,
      form: {
        is_employee: this.isEmployee,
        secondment_client: false,
        secondment_contractor: false,
        secondment_partner: false,
        avatar_tus: null,
        name: null,
        surname: null,
        date_birth: null,
        place_birth: null,
        marital_status: null,
        gender: "MA",
        citizenship_set: [],
        telcontact_set: [],
        extraemail_set: [],
        physicalpersonaddress_set: {
          address: null,
          description: null,
          kind: "0",
          main: true,
          manual_address: false,
          composed_address: null
        },
      },
      addressKinds: [],
      GENDERS: [],
      TELEPHONE_KINDS: [],
      EMAIL_KINDS: [],
      SOCIAL_KINDS: [],
      MARITAL_STATUS: [],
      addressCenter: null,
      nations: [],
      userEmail: "",
      googleMapCall: false,
      addEmployeeContract: false,
    };
  },

  computed: {
    ...mapGetters("user", ["date_format", "dateFormat", "isAfterSale"]),
    ...mapState("constants", ["countries"]),

    requestBody() {
      const form = { ...this.form };

      if (!form.physicalpersonaddress_set || !form.physicalpersonaddress_set.address) {
        form.physicalpersonaddress_set = [];
      } else {
        form.physicalpersonaddress_set = [{ ...form.physicalpersonaddress_set, main: true }];
      }

      if (this.socialContactsCpy.length) {
        form.socialcontact_set = this.socialContactsCpy;
      }

      if (this.website) {
        form.physicalpersonwebsite_set = [
          {
            website: this.website,
          },
        ];
      }

      return form;
    },

    selectableKinds() {
      return this.addressKinds.map(el => ({ value: String(el.value), text: el.display_name }));
    },

    nationsSelectable() {
      return this.nations
        .map((x) => ({ text: this.$t(x.name), value: String(x.iso) }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },

    citizenshipsComp: {
      get() {
        return this.form.citizenship_set.map(el => this.$t(el.country));
      },
      set(val) {
        this.form.citizenship_set = val.map(el => ({ country: el }));
      },
    },

    observerErrors() {
      return this.$refs.mainValidationObserver.errors;
    },

    computedMsg() {
      let msg = this.$t("Something went wrong, please check your data");
      for (const el in this.observerErrors) {
        if (this.observerErrors[el].length) {
          msg = this.observerErrors[el][0];
          break;
        }
      }
      return msg;
    },

    buttonDisabled() {
      return this.isAfterSale;
    }
  },

  watch: {
    errorMessages: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.mainValidationObserver;
        const newErrors = getValidationErrors(val, this.requestBody);

        if (newErrors?.extraemail_set.length) {
          newErrors.extraemail_set.forEach((el, idx) => {
            el.email ? (newErrors[`email[${idx}]`] = el.email[0]) : {};
          });
        }

        if (newErrors?.telcontact_set.length) {
          newErrors.telcontact_set.forEach((el, idx) => {
            el.number ? (newErrors[`phone[${idx}]`] = el.number[0]) : {};
          });
        }

        formObs.setErrors({ ...newErrors });
      },
    },
    "form.citizenship_set.length"(val) {
      if (val) {
        this.geolocate(this.form.citizenship_set[0].country);
      }
    },
    website(val) {
      if (val.trim().length > 8 && !val.trim().startsWith("http://") && !val.trim().startsWith("https://")) {
        this.website = this.formatWebsite();
      }
    },
    isEmployee: {
      immediate: true,
      handler(newVal) {
        this.form.is_employee = newVal;
      }
    },
    "form.physicalpersonaddress_set.kind"(newVal, oldVal) {
      if (newVal == null) {
        this.form.physicalpersonaddress_set.kind = oldVal
      }
    }
  },

  async mounted() {
    try {
      this.googleMapCall = false;
      const [genders, telephoneKinds, emailKinds, addressKinds, socialKinds, maritalStatus] = await Promise.all([
        PhysicalPersonDetailService.getGenders(),
        PhysicalPersonTelephoneService.getKinds(),
        PhysicalPersonEmailService.getKinds(),
        PhysicalPersonAddressService.getKinds(),
        PhysicalPersonSocialService.getKinds(),
        PhysicalPersonDetailService.getMaritalStatus()
      ]);
      this.nations = this.countries;
      this.GENDERS = genders.map(el => ({ value: String(el.value), text: el.display_name }));
      this.TELEPHONE_KINDS = telephoneKinds.map(el => ({ value: String(el.value), text: el.display_name }));
      this.EMAIL_KINDS = emailKinds.map(el => ({ value: String(el.value), text: el.display_name }));
      this.SOCIAL_KINDS = socialKinds.map(el => ({ value: String(el.value), text: el.display_name }));
      this.addressKinds = addressKinds;
      this.googleMapCall = true;
      this.MARITAL_STATUS = maritalStatus.map(el => ({ value: String(el.value), text: el.display_name }));
    } catch (err) {
      console.error(err);
      this.$bvToast.toast("Failed to init. Please try later.", {
        title: "Error",
        variant: "danger",
        solid: true,
        noAutoHide: true,
      })
    }
  },

  methods: {
    moment,

    formatWebsite() {
      const https = "https://";
      const http = "http://";
      let site = this.website.trim();
      if (!site.startsWith(https) && !site.startsWith(http)) site = https + site;

      return site;
    },

    async handleNext() {
      const validator = this.$refs[`validator-${this.$refs.physicalPersonCreateWizard.getStep()}`];

      if (validator) {
        await validator.reset();
        this.form.telcontact_set = this.form.telcontact_set.filter(el => el.number != '');
        this.form.extraemail_set = this.form.extraemail_set.filter(el => el.email != '');
        const isValid = await validator.validate();
        if (!isValid) {
          if (this.$refs.physicalPersonCreateWizard.getStep() === 3) {
            await Swal.fire({
              title: this.$t("Caution"),
              text: this.$t("Seems like some fields are missing, do you want to continue?(You can modify them and then validate the address with the validate address button)"),
              icon: "warning",
              confirmButtonClass: "btn btn-primary",
              confirmButtonText: this.$t("Yes"),
              showCancelButton: true,
              reverseButtons: true,
              cancelButtonText: this.$t("No"),
              cancelButtonClass: "btn",
            }).then(res => {
              if (!res.isConfirmed) {
                this.$refs.physicalPersonCreateWizard.goTo(2);
              }
            });
          } else {
            this.$nextTick();
            return;
          }
        }
      }
      this.$refs.mainValidationObserver.handleSubmit(this.$refs.physicalPersonCreateWizard.goNext());
    },

    submit() {
      if (this.canAddAsEmployee && this.form.is_employee) {
        this.$emit("submit-as-employee", this.requestBody, this.addEmployeeContract);
      } else {
        this.$emit("submit", this.requestBody);
      }
    },

    async geolocate(countryCode) {
      if (!countryCode) {
        this.addressCenter = null;
        return;
      }
      const country = await this.nationsSelectable.find(el => el.value === countryCode);
      if (!country) return;
      const countryName = country.text;
      const res = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${countryName}&key=${process.env.VUE_APP_GOOGLE_MAPS_KEY}`,
      );
      this.addressCenter = res.data.results[0].geometry.location;
    },
    changeManualAddress(val) {
      this.form.physicalpersonaddress_set.manual_address = val
    },
    updateComposedAddress(val) {
      this.form.physicalpersonaddress_set.composed_address = val;
    }
  },
};
</script>

<style scoped>
.custom-wizard-step {
  max-width: 600px;
  margin: 0 auto;
}
</style>
